<template>
  <div id="privacy-settings">
    <h2 class="mb-1 hidden-xs-only">
      {{ $t('Pages.Settings.privacy_settings') }}
    </h2>
    <p class="mb-4">
      {{ $t('Pages.Settings.privacy_text') }}
    </p>
    <!--
    <div class="top-card mb-4">
      <div
        is="CustomCard"
        :title="$t('Pages.Settings.download_info')"
        icon="cloud_download"
      >
        <v-form
          id="download-information-form"
          ref="form"
          label-width="100px"
          class="no-required-mark"
        >
          <p>{{ $t('Pages.Settings.download_copy') }}</p>
          <div class="button-container">
            <v-btn
              id="download-data-btn"
              class="cta-btn"
              rounded
              depressed
              @click="$router.push({ name: 'settings-export' })"
            >
              {{ $t('Pages.Settings.download_zip') }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
    -->
    <div class="top-card mb-4">
      <div
        is="CustomCard"
        title="Terms &amp; Policy"
        icon="description"
      >
        <v-form
          id="terms-privacy-form"
          ref="form"
          label-width="100px"
          class="no-required-mark"
        >
          <p>
            {{ $t('Pages.Settings.terms_text_1') }}
            <router-link
              :to="{ name: 'tos-users' }"
              target="_blank"
              rel="noreferrer"
              v-text="$t('Footer.terms')"
            />
            {{ $t('Pages.Settings.terms_text_2') }}
            <router-link
              :to="{ name: 'privacy-policy' }"
              target="_blank"
              rel="noreferrer"
              v-text="$t('Footer.privacy')"
            />
          </p>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCard from '@/common/CustomCard.vue'

export default {
  name: 'SettingsPrivacy',
  components: {
    CustomCard
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {
      }
    }
  }
}
</script>

<style>
hr.style {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
